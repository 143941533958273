import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import classNames from 'classnames';

import './App.css';

const toDate = (d) => {
  try {
    const date = new Date(d);
    if (isNaN(date) || d === null) return null;
    return date;
  } catch (err) {
    return null;
  }
};

function App() {
  const [vehicles, setVehicles] = useState([]);

  const updateVehicles = async () => {
    try {
      const response = await axios.get(
        'https://api.nawgati.com/puccservice/vehicle/latest'
      );

      const newVehicles = [];
      response.data.vehicles.forEach((vehicle) => {
        const { regNo } = vehicle;
        for (let i = 0; i < newVehicles.length; i++)
          if (newVehicles[i].regNo === regNo) return;

        const timestamp = toDate(vehicle.timestamp);
        const puccUpto = toDate(vehicle.puccUpto);

        newVehicles.push({
          regNo,
          puccUpto: puccUpto === null ? '' : puccUpto.toLocaleDateString(),
          timestamp: timestamp.toLocaleTimeString().substring(0, 5),
          isPuccValid: puccUpto !== null && puccUpto >= timestamp,
        });
      });
      setVehicles(newVehicles);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    updateVehicles();
  }, []);

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">PUCC Alerter</h1>
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Registration Number</th>
              <th>Last Seen</th>
              <th>PUCC Valid Upto</th>
              <th>Is PUCC Valid?</th>
            </tr>
          </thead>
          <tbody>
            {vehicles.map((vehicle, i) => (
              <tr
                key={i}
                className={classNames({
                  'has-text-danger': !vehicle.isPuccValid,
                })}
              >
                <td>{i + 1}</td>
                <td className="is-family-monospace has-text-weight-bold">
                  {vehicle.regNo.toUpperCase()}
                </td>
                <td>{vehicle.timestamp}</td>
                <td>{vehicle.puccUpto}</td>
                <td>
                  {vehicle.isPuccValid ? (
                    <span className="tag is-success">Yes</span>
                  ) : (
                    <span className="tag is-danger">No</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default App;
